<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Url</h3>
          <div class="d-flex justify-content-between">
            <div class="col-md-4 mb-4" align="left">
              <label for="project" class="form-label">Date</label>
              <v-date-picker v-model="GetUrlPost.date" mode="Date" :model-config="modelConfig" @dayclick="GetUrls()">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="form-control" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div v-if="ListUrls.status != 'false'" class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important"></div>
              <!-- Tabla-->
              <div class="table-responsive">
                <table id="url-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Browser</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Title</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">URL</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Time spent</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListUrls.status != 'false'" v-for="(obj, key, index) in ListUrls.list" :key="index">
                      <td class="text-start">
                        {{obj.browser}}
                      </td>
                      <td class="text-start">{{obj.title_page}}</td>
                      <td v-if="obj.url != 'no-url'" class="text-start">
                        <a target="_blank" :href="'https://'+obj.url">{{obj.domain}}</a>
                      </td>
                      <td v-else class="text-start">{{obj.domain}}</td>
                      <td class="text-start">{{obj.seconds}}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  import moment from 'moment-timezone'

  export default 
  {
    name: 'Url',
    data: () => (
    {
      GetUrlPost: 
      {
        date: "",
        timezone: moment.tz.guess(true)
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      CurrentDate: 
      {
        date: "",
        timezone: moment.tz.guess(true)
      },
      ListUrls: 
      {
        status: "false",
        list: {}
      }
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      GetUrls() 
      {
        Api.post("/url/list", this.GetUrlPost).then((result) => 
        {
          this.ListUrls.status = result.data.status
          
          if (this.ListUrls.status == "true") 
          {
            this.ListUrls.list = result.data.url;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      GetCurrentDate() 
      {
        var currentDateWithFormat = this.ActualDate();//new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.GetUrlPost.date = currentDateWithFormat
      },
      InitDatatable() 
      {
        var table = $('#url-table').DataTable(
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      }
    },
    mounted() 
    {
      this.GetCurrentDate()
      this.GetUrls()
    }
  }
</script>

<style scoped></style>
